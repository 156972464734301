import iconDelivery from "../../images/icons/styled/first/Delivery.svg";
import iconTraining from "../../images/icons/styled/first/Training.svg";
import iconUserCheck from "../../images/icons/styled/first/UserCheck.svg";
import imageScheme from "../../images/schemes/payouts_scheme.svg";
import iconLightning from "../../images/icons/styled/first/Lightning.svg";
import iconCheck from "../../images/icons/styled/first/CheckInCircle.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconAPI from "../../images/icons/styled/first/API.svg";
import iconFlag from "../../images/icons/styled/first/Flag.svg";
import links from "../../utils/links";


export default {
    "seo": {
        "title": "Выплаты самозанятым и фрилансерам на счета, банковские карты и электронные кошельки  | Банк 131",
        "description": "Массовые выплаты физлицам. Помогаем проверить налоговый статус физлица в ФНС, зарегистрировать как самозанятого, присылаем чек для бухгалтерии. Решение для платформ онлайн-обучения, платформ для фрилансеров. Для международных компаний — выплаты исполнителям в России."
    },
    "pageHeaderBlock": {
        "title": "Выплаты самозанятым и&nbsp;физическим лицам",
        "text": "Переводите деньги исполнителям на российские и зарубежные банковские карты, счета и электронные кошельки. <br />Быстрые и удобные выплаты физлицам по API."
    },
    "infoCardsBlock": {
        "listTitle": "Для интернет-компаний",
        "list": [
            'Для российских и международных сервисов, которые работают с физлицами в России: фрилансерами, дистанционными специалистами и распределенными командами',
            'Для компаний, которые работают или только планируют работать с самозанятыми и физлицами в России'
        ],
        "cards": [
            {
                "title": 'Сервисам доставки<br />и интернет-магазинам',
                "text": 'Для удобных выплат курьерам',
                "icon": iconDelivery,
                "description": '',
                "theme": 'cardFirst'
            },
            {
                "title": 'Образовательным<br /> онлайн-платформам',
                "text": 'Для оплаты работ репетиторов и преподавателей',
                "icon": iconTraining,
                "description": '',
                "theme": 'cardFirst'
            },
            {
                "title": 'Биржам фрилансеров',
                "text": 'Для быстрых выплат дизайнерам, копирайтерам, веб-мастерам, переводчикам – и другим исполнителям',
                "icon": iconUserCheck,
                "description": '',
                "theme": 'cardFirst'
            }
        ]
    },
    "stepsBlock": {
      "title": "Как проходят выплаты самозанятым",
      "list": [
          {
              "title": 'Проверка',
              "text": 'Проверим налоговый статус физлица по ИНН — напрямую в ФНС.'
          },
          {
              "title": 'Выплата',
              "text": 'Перечислим деньги на банковский счет, карту или электронный кошелек. Зарегистрируем доход в ФНС.'
          },
          {
              "title": 'Подтверждение',
              "text": 'Отправим вам и самозанятому электронный чек.',
              "image": imageScheme,
              "imageLabel": 'Посмотреть схему'
          }
      ]
    },
    "infoCardsBlockSecond": {
        "cardsTitle": "Вам понравится",
        "cards": [
            {
                "title": 'Простой процесс, мгновенные транзакции',
                "text": 'Данные о получателях и суммах можно передать по API. Выплата отправляется мгновенно, а исполнитель получает ее в течение нескольких минут. Отчеты о выплатах мы формируем ежедневно и отправляем вам на почту.',
                "icon": iconLightning,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Прямая интеграция <br />с ФНС',
                "text": 'Банк 131 аккредитован ФНС. Мы проверяем налоговый статус получателя, регистрируем самозанятых, формируем фискальный чек за выплаты.',
                "icon": iconFlag,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Ответственный подход к вопросам безопасности',
                "text": 'Персональные и платежные данные получателей получаем и храним, соблюдая требования законов и платежных систем. Для API интеграций мы предусмотрели надежное шифрование.',
                "icon": iconShield,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Лёгкая интеграция через API',
                "text": 'Мы сфокусировались на продукте, <br />и автоматизируем то, что вы привыкли делать руками. Сделали API интеграцию понятной и добавили возможность тестирования до работы с реальными данными',
                "icon": iconAPI,
                "description": '',
                "theme": 'withoutBackground'
            },
            {
                "title": 'Соответствие требованиям ',
                "text": 'Мы проверяем правомерность основания для выплат. Все выплаты проводятся в строгом соответствии с Налоговым Кодексом и 115-ФЗ.',
                "icon": iconCheck,
                "description": '',
                "theme": 'withoutBackground'
            }
        ]
    },
    "infoCardsBlockThird": {
        "listTitle": "С чего начать",
        "list": [
            `Отправить заявку на почту <a href="${links.ru.api}" target="_blank" rel="noreferrer">partners@131.ru</a> или оставить свой контакт в форме обратной связи ниже `,
            `Подписать договор и начать интеграцию <a href="${links.ru.api}" target="_blank" rel="noreferrer">нашему API</a>`,
            'Готово'
        ],
        "listGoTo": {
            "text": 'Тарифы',
            "to": '/pricing'
        }
    },
    "submitApplicationBlock": {
        "title": "Как подключить выплаты",
        "textSubmit": "Узнать больше"
    }
}
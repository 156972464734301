import React from 'react';

import Layout from "../../layout/Layout";
import PageHeaderBlock from "../../blocks/PageHeaderBlock";
import SEO from "../../components/seo";
import InfoCardsBlock from "../../blocks/InfoCardsBlock";
import StepsBlock from "../../blocks/StepsBlock";
import SubmitApplicationBlock from "../../blocks/static/SubmitApplicationBlock";

import enMessages from '../../i18n/payouts/en.js'
import ruMessages from '../../i18n/payouts/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const PayoutPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO title={messages[locale].seo.title}
                 description={messages[locale].seo.description}
            />
            <PageHeaderBlock
                locale={locale}
                title={messages[locale].pageHeaderBlock.title}
                text={messages[locale].pageHeaderBlock.text}
            />
            <InfoCardsBlock
                cardsTitleColor="first"
                cards={messages[locale].infoCardsBlock.cards}
                listTitle={messages[locale].infoCardsBlock.listTitle}
                list={messages[locale].infoCardsBlock.list}
                listColor="first"
                haveQuestions={false}
            />
            <StepsBlock
                title={messages[locale].stepsBlock.title}
                list={messages[locale].stepsBlock.list}
                listColor="second"
                listType="ol"
                config={{
                    baseBorder: true,
                    animation: '2'
                }}
            />
            <InfoCardsBlock
                cards={messages[locale].infoCardsBlockSecond.cards}
                cardsLayout="column"
                cardsTitle={messages[locale].infoCardsBlockSecond.cardsTitle}
                cardsTitleColor="grey"
                config={{
                    underPrev: true
                }}
            />
            <InfoCardsBlock
                haveQuestions={true}
                listTitle={messages[locale].infoCardsBlockThird.listTitle}
                list={messages[locale].infoCardsBlockThird.list}
                listColor="second"
                listGoTo={messages[locale].infoCardsBlockThird.listGoTo}
                config={{
                    symmetric: true
                }}
            />
            <SubmitApplicationBlock
                title={messages[locale].submitApplicationBlock.title}
                textSubmit={messages[locale].submitApplicationBlock.textSubmitBottom || messages[locale].submitApplicationBlock.textSubmit}
            />
        </Layout>
    );
};

export default PayoutPage;
